<template>
  <div class="list">
    <div v-if="loading">
      <h2>正在加载中......</h2>
    </div>
    <div v-else-if="list.length==0 && !loading">
      <h2 style="margin:30px auto;text-align:center">暂无数据</h2>
    </div>
    <div v-else-if="list.length > 0 && !loading">
      <li v-for="i in list" class="list-item">
        <h3>
          <span class="check-time">{{i.createdAt}}</span>
          <span v-if="i.status==0" class="check-status info">未审核</span>
          <span v-else-if="i.status==1" class="check-status info">支付中</span>
          <span v-else-if="i.status==2" class="check-status success">已通过</span>
          <span v-else-if="i.status==3" class="check-status error">支付失败</span>
          <span v-else-if="i.status==4" class="check-status error">已拒绝</span>
        </h3>
        <p v-if="i.status==4">拒绝原因：{{i.remark}}</p>
        <p v-if="i.returnFee || i.status==0">返款金额（￥）：{{i.returnFee}}</p>
      </li>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      loading:true,
      list:[

      ]
    }
  },
  mounted(){
    this.get()
  },
  methods:{
    get(){
      this.$http.get('/api/wechat/paginateCashBacks').then(res=>{
        if(res.data.status.errCode === 200){
          this.list = res.data.data.array
          this.loading = false
        }
      })
    }
  }
}
</script>


<style scoped>

</style>